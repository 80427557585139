import { Component } from '@angular/core';

@Component({
  selector: 'app-photo-searchbar',
  standalone: true,
  imports: [],
  templateUrl: './photo-searchbar.component.html',
  styleUrl: './photo-searchbar.component.css'
})
export class PhotoSearchbarComponent {

}
