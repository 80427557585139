import { CommonModule,JsonPipe } from '@angular/common';
import { Component, OnInit,Input,inject, Output,EventEmitter } from '@angular/core';
import { PhotoPile, Photo} from '../../interfaces/photo-pile';
import { UploadSuccessService } from '../../services/upload-success-service';
import { RouterModule } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DeleteResponse, selectedPhotoDownloadResponse } from '../../interfaces/response';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {FormBuilder, FormsModule, ReactiveFormsModule,FormGroup } from '@angular/forms';
import {MultiSelectedDictionary} from '../../interfaces/dictionary';
import { environment } from '../../environment/local_environment';
@Component({
  selector: 'app-photo-list',
  standalone: true,
  imports: [CommonModule,RouterModule,MatCheckboxModule,FormsModule,ReactiveFormsModule,JsonPipe],
  templateUrl: './photo-list.component.html',
  styleUrl: './photo-list.component.css'
})

export class PhotoListComponent implements OnInit{
  
  constructor(private http: HttpClient,private UploadSuccessService: UploadSuccessService,private snackBar: MatSnackBar) { }
  // photosToBeLoaded 是一个 string 或者 number[] 类型的变量，它的值是从父组件传递过来的，决定是加载特定图片组还是加载所有图片组
  private readonly formBuilder = inject(FormBuilder);
  public selectedPhotoIDs:FormGroup = this.formBuilder.group({});
  @Input() photosToBeLoaded: string | undefined;
  photopiles: PhotoPile[] = [];
  uid: string = '';
  // generate a dictionary contains all photopiles groupid and false
  ngOnInit() {
    this.UploadSuccessService.uploadSuccess.subscribe(() => {
      this.LoadAllPhotopiles();
    });
    if (this.photosToBeLoaded === 'All') {
    this.LoadAllPhotopiles();
  }
   else if(this.photosToBeLoaded){
    this.LoadPhotopilesByUid(this.photosToBeLoaded);
   }
   else{
    console.log('No photos to be loaded');
   }
  }
  LoadAllPhotopiles() {
    this.http.get<PhotoPile[]>(environment.backendUrl+'/loadAllPhotoPiles').subscribe({
      next: (response) => {
        this.photopiles = response;
      },
    });
  }
  LoadPhotopilesByUid(uid: string) {
    const params = new HttpParams().set('uid', uid);
    this.http.get<PhotoPile[]>(environment.backendUrl+'/loadPhotoPilesByUid', { params }).subscribe({
      next: (response) => {
        this.photopiles = response;
        var photoIDDict = this.photopiles.reduce((acc:MultiSelectedDictionary, cur:PhotoPile) => {
          var GroupIDStr = cur.GroupID.toString();
          acc[GroupIDStr] = false;
          return acc;
        }, {});
          this.selectedPhotoIDs = this.formBuilder.group(photoIDDict);
      },
    });
  }

  onDragStart(event: DragEvent, photopile: PhotoPile) {
    event.dataTransfer?.setData('application/json', JSON.stringify(photopile));
    const draggingElement = event.target as HTMLElement;
    draggingElement.classList.add('dragging');
  }

  deletePhotopile(photopile: PhotoPile) {
    this.http.post<DeleteResponse>(environment.backendUrl+'/deletePhotoPile', JSON.stringify(photopile)).subscribe({
      next: (response) => {
        if (response.isPhotoDeleted) {
          this.snackBar.open(photopile.GroupName +' Deleted!', 'Close', {
            duration: 2000,
          });
        }else{
          this.snackBar.open(photopile.GroupName +' Delete Failed!', 'Close', {
            duration: 2000,
          });
        }
        this.LoadAllPhotopiles();
      },
    });
  }
  countselectedPhotoIDs(): number {
    return Object.values(this.selectedPhotoIDs.value).filter((v) => v).length;
  }
  async downloadImage(imageUrl: string, filename: string): Promise<void> {
    try {
        // 通过 fetch 获取图片数据
        const response = await fetch(imageUrl);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        // 将图片数据转换为 Blob 对象
        const blob = await response.blob();

        // 创建一个 Blob URL
        const blobUrl = URL.createObjectURL(blob);

        // 创建一个 <a> 元素
        const link = document.createElement('a');
        link.href = blobUrl;

        // 设置下载文件的名称
        link.download = filename;

        // 自动点击链接以触发下载
        link.click();

        // 释放 Blob URL
        URL.revokeObjectURL(blobUrl);
    } catch (error) {
        console.error('Error downloading the image:', error);
    }
}
//   downloadImage(imageUrl: string, filename: string): void {
//     // 使用 fetch 获取图片数据
//     fetch(imageUrl)
//         .then(response => {
//             if (!response.ok) {
//                 throw new Error('Network response was not ok');
//             }
//             // 将响应转换为 Blob 对象
//             return response.blob();
//         })
//         .then(blob => {
//             // 创建一个 Blob URL
//             const blobUrl = URL.createObjectURL(blob);

//             // 创建一个 <a> 元素
//             const link = document.createElement('a');
//             link.href = blobUrl;

//             // 设置下载文件的名称
//             link.download = filename;

//             // 自动点击链接以触发下载
//             link.click();

//             // 释放 Blob URL
//             URL.revokeObjectURL(blobUrl);
//         })
//         .catch(error => {
//             console.error('Error downloading the image:', error);
//         });
// }
  downloadselectedPhotoIDs() {
    // only keep item of true values in the dictionary
    var selectedPhotoIDs = Object.keys(this.selectedPhotoIDs.value).filter((key) => this.selectedPhotoIDs.value[key]);
    //convert string to number
    var selectedPhotoIDNumbers = selectedPhotoIDs.map(Number);
    // send request to backend to download selected photos
    this.http.post<selectedPhotoDownloadResponse>(environment.backendUrl+'/getOriginalLinks', JSON.stringify(selectedPhotoIDNumbers)).subscribe({
      next: (response) => {
        var selectedPhotoDownloadLinks = response.originalLinks;
        // for each link, download the photo
        selectedPhotoDownloadLinks.forEach((link) => {
          var fileName = link.split('/').pop() || 'download';
          this.downloadImage(link, fileName);
        });
      }
});
  }
}