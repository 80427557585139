import { Component, Input, Output, ViewChild, ElementRef, AfterViewInit, EventEmitter, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { devices } from '../../interfaces/devices';
import { Observable } from 'rxjs';
import { Store,StoreModule } from '@ngrx/store';
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatButtonModule} from '@angular/material/button';
@Component({
  selector: 'app-device-list',
  standalone: true,
  imports: [CommonModule,StoreModule,MatSidenavModule,MatButtonModule],
  templateUrl: './device-list.component.html',
  styleUrl: './device-list.component.css'
})
export class DeviceListComponent {
  // output the width of device panel elem
  @Output() devicePanelWidth = new EventEmitter<number>();
  @ViewChild('devicePanel') devicePanel!: ElementRef;
  @Input() isVisiable: boolean = false;
    devices = [...devices];
    // how to define current device are waiting to be implemented
    currentDevice = devices[0];
    constructor(private store: Store<{ deviceListVisibility: boolean; }>) { }
    deviceListVisibility: Observable<boolean> = this.store.select('deviceListVisibility');
    // emit the width of device panel elem when the wdith is changed
    emitNewWidth() {
      var devicePanelWidth:number = this.devicePanel.nativeElement.offsetWidth;
      this.devicePanelWidth.emit(devicePanelWidth);
      console.log(devicePanelWidth);
    }
    moveoutToggleDeviceListVisibility() {
      if (this.isVisiable) {
      this.isVisiable = !this.isVisiable;
      setTimeout(() => {
        this.emitNewWidth();
        console.log(this.isVisiable);
      }, 3);
      }
    }
    toggleDeviceListVisibility() {
      this.isVisiable = !this.isVisiable;
      setTimeout(() => {
        this.emitNewWidth();
        console.log(this.isVisiable);
      }, 3);
    }
    getElementWidth() {
      return this.devicePanel.nativeElement.offsetWidth;
    }
}
