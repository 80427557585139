<div class = " flex flex-col content-center" name = "photo-share-section">
    <!-- ngModel 将input的值绑定到input的属性上 -->
    <div (dragover)="onDragOver($event)" 
    (drop) = "onDrop($event)" 
    (dragenter)="onDragEnter($event)" 
    (dragleave)="onDragLeave($event)"
    id = "drag-drop-area" class = "scrollable-container bg-slate-200 w-full h-40 rounded-lg p-1 mt-4 flex flex-wrap overflow-auto gap-1">
        <div *ngFor = "let photopile of shareList.Photopiles" (dragstart) = "onDragStart($event, photopile)" draggable="true" style = "width: 40%;height: 20%">
            <button class = "absolute bg-slate-100 border-none cursor-pointer z-10 w-3 h-3 rounded-sm text-white hover:bg-red-500" (click) = "removePhotopileFromSharedList(photopile)">
                 <p class = 'delete-text'>x</p>
            </button>
            <img src = {{photopile.Photos[0].PhotoLink}} alt = "photo" class = "rounded-md">
        </div>
        <!-- <p *ngIf="!previewUrl">Drag and drop an image here</p>
        <img *ngIf="previewUrl" [src]="previewUrl" alt="Image Preview" width="300"> -->
    </div>
    <div>
    <input class = "w-1/3 rounded-md m-2 p-1" type = "number" [(ngModel)]="effectivePeriodValue" [defaultValue]="30" value = "30"/>
    <span class = "mr-5"> days </span>
    <button class = "rounded-md pl-2 pr-2 pt-1 pb-1 w-min h-min text-white text-sm hover:bg-sky-400 hover:shadow-md" (click) = "generateShareLink()" type = 'submit' style="background-color: #66ccff;"> Share! </button>
    </div>
    <div class="overlay" *ngIf = "isPopupOpen" (click) = "closePopup()"></div>
    <div class="popup" *ngIf = "isPopupOpen">
        <input class="absolute rounded-md border-gray-200 p-2 bg-slate-200 text-center" type="text" [value]="shareLink" readonly #linkInput>
        <button id = "copy-button" class="pl-2 pr-2 pt-1 pb-1 rounded-md absolute"(click)="copyLink(linkInput)">Copy Link</button>
        <button id = "close-button" class="pl-2 pr-2 rounded-md bg-red-400 cursor-pointer close-button w-min h-min absolute hover:bg-red-600"(click)="closePopup()">x</button>
    </div>
</div>
