<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
<div class="container" class = "flex flex-row flex-1 h-max">
    <router-outlet></router-outlet>
  
    <div name = "left-row" class="flex fixed flex-col p-4 space-y-4 w-auto z-50 bg-slate-100 content-center h-screen" style = "transition: all 0.5s ease; " #parentElement>
      <div name = "menu-button" class = "pl-4" style = "font-size: 18px;">
        <button class = "button" (click) = "toggleDeviceListVisibility()">
          <i class="fas fa-bars"></i>
        </button>
      </div>
      <app-device-list [isVisiable]="parentIsVisiable" (devicePanelWidth) = "onWidthChange($event)" style="position:relative" #devicePanel></app-device-list>
      <div name = "transparentElemForDeviceList" #transparentElement style = "transition: all 0.5s ease; "></div> 
      <div name = "user-img" class = "w-max absolute bottom-3">
        <img src="./imgs-test/img2.jpg" alt="User Photo" class="w-10 h-10 rounded-full ">
      </div>
    </div>
  
    <div name = "middle-row" class="flex relative z-0 basis-5/6 flex-col p-4 space-y-5 h-screen pl-32 bg-slate-100 max-w-fit w-52">
      <div name = "top-function-area" class = "flex flex-row">
        <p class = "font-sans text-3xl font-medium"> Recent Stash-es</p>
        <app-upload-section class = "ml-auto"></app-upload-section>
      </div>
      <app-photo-searchbar name = "app-photo-searchbar"></app-photo-searchbar>
      <app-photo-list class="scrollable-container overflow-auto h-screen bg-white p-3 rounded-lg" photosToBeLoaded="All"></app-photo-list>
    </div>
  
    <div name = "right-row" class="basis-1/6 flex-col h-screen bg-slate-100">
      <app-photo-share-section></app-photo-share-section>
    </div>
</div>