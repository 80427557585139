import { Component,ElementRef,HostListener, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { UploadResponse } from '../../interfaces/response';
import { UploadSuccessService } from '../../services/upload-success-service';
import { environment } from '../../environment/local_environment';
@Component({
  selector: 'app-upload-section',
  standalone: true,
  imports: [CommonModule, FormsModule, MatSnackBarModule],
  templateUrl: './upload-section.component.html',
  styleUrl: './upload-section.component.css'
})


export class UploadSectionComponent {
  menuVisible: boolean = false;
  selectedPhoto: File | null = null;
  previewUrl: string | ArrayBuffer| null = null;
  @ViewChild('fileInput') fileInput!: ElementRef;
  constructor(private http: HttpClient, private snackBar: MatSnackBar, private uploadSuccessService: UploadSuccessService,private el: ElementRef) { }
  
  onSubmit(){
    console.log('Uploaded!');
    const formData = new FormData();
    const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
    if (fileInput && fileInput.files && fileInput.files.length > 0) {
      formData.append('photo', fileInput.files[0]);
      this.http.post<UploadResponse>(environment.backendUrl+'/upload', formData).subscribe({
        next: (response) => {
          console.log(response);
          console.log(response.isPhotoDuplicate);
          this.showSuccessMessage(response.fileName);
          this.showDuplicateMessage(response.isPhotoDuplicate, response.fileName);
          if (!response.isPhotoDuplicate){
          this.uploadSuccessService.notifyUploadSuccess();
          // refresh itself display
          this.previewUrl = null;
          // delete the file in the input
          fileInput.value = '';
          }
        },
      });
    }
  }
  showSuccessMessage(fileName: string) {
    this.snackBar.open(fileName +' upload successful!', 'Close', {
      duration: 2000,
    });
  }
  showDuplicateMessage(isDuplicate: boolean, fileName: string) {
    if (isDuplicate) {
      this.snackBar.open('File ' + fileName + ' already exists!', 'Close', {
        duration: 2000,
      });
    }
  }
  onPhotoSelection(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput && fileInput.files && fileInput.files.length > 0) {
      this.selectedPhoto = fileInput.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.previewUrl = reader.result;
      };
      reader.readAsDataURL(this.selectedPhoto);
    }
    console.log('Uploaded!');
    const formData = new FormData();
    if (fileInput && fileInput.files && fileInput.files.length > 0) {
      formData.append('photo', fileInput.files[0]);
      this.http.post<UploadResponse>(environment.backendUrl+'/upload', formData).subscribe({
        next: (response) => {
          console.log(response);
          console.log(response.isPhotoDuplicate);
          this.showSuccessMessage(response.fileName);
          this.showDuplicateMessage(response.isPhotoDuplicate, response.fileName);
          if (!response.isPhotoDuplicate){
          this.uploadSuccessService.notifyUploadSuccess();
          // refresh itself display
          this.previewUrl = null;
          // delete the file in the input
          fileInput.value = '';
          }
        },
      });
    }
  }
  toggleMenuVisibility() {
    this.menuVisible = !this.menuVisible;
  }
  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if (!this.el.nativeElement.contains(event.target)) {
      this.menuVisible = false;
    }
  }
  openFileSelector() {
    this.fileInput.nativeElement.click();
  }
}