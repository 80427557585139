<!-- <div id = 'photo-panel' class = "grid grid-cols-4 gap-6 h-max">
  <div *ngFor="let photopile of photopiles" class = "w-full h-max" 
draggable="true" (dragstart) = "onDragStart($event, photopile)">
    <div class = "w-full h-max">
      <div *ngIf="photopile.Photos && photopile.Photos.length > 1" class = "-mb-24 ml-4 w-full h-28 bg-slate-600"></div>
      <div *ngIf="photopile.Photos && photopile.Photos.length > 1" class = "-mb-20 ml-2 w-full h-28 bg-slate-500"></div>
      <img src = {{photopile.Photos[0].PhotoLink}} class = "w-full h-28 object-cover rounded-md" alt = {{photopile.Photos[0].PhotoName}}/>     
    </div>
  </div>
</div> -->
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
<section *ngIf = "photosToBeLoaded == 'All';else elseBlock">
  <div class = "rounded-2xl photo-container" *ngFor="let photopile of photopiles" style="width:{{photopile.Photos[0].PhotoWidth*200/photopile.Photos[0].PhotoHeight}}px;flex-grow:{{photopile.Photos[0].PhotoWidth*200/photopile.Photos[0].PhotoHeight}}" draggable="true" (dragstart) = "onDragStart($event, photopile)">
    <i style="padding-bottom:{{photopile.Photos[0].PhotoHeight/photopile.Photos[0].PhotoWidth*100}}%"></i>
    <img src="{{photopile.Photos[0].PhotoLink}}" alt="" class = "rounded-2xl">
    <button class="delete-btn hover:bg-sky-400 hover:shadow-md" (click)="deletePhotopile(photopile)">
      <i class="fas fa-trash"></i>
    </button>
  </div>
</section>

<ng-template #elseBlock>
  <section class = "rounded-md p-5" [formGroup]="selectedPhotoIDs">
    <div class = "rounded-2xl photo-container" *ngFor="let photopile of photopiles" style="width:{{photopile.Photos[0].PhotoWidth*200/photopile.Photos[0].PhotoHeight}}px;flex-grow:{{photopile.Photos[0].PhotoWidth*200/photopile.Photos[0].PhotoHeight}}" draggable="true" (dragstart) = "onDragStart($event, photopile)">
      <mat-checkbox formControlName = "{{photopile.GroupID}}"></mat-checkbox>
      <i style="padding-bottom:{{photopile.Photos[0].PhotoHeight/photopile.Photos[0].PhotoWidth*100}}%"></i>
      <img id = "{{photopile.GroupID.toString()}}" src="{{photopile.Photos[0].PhotoLink}}" alt="" class = "rounded-2xl">
    </div>
    <div class = "download-buttom-float bg-slate-200 fixed z-50 rounded-md p-2 pt-4">
      <p class = "text-gray-700 w-3/5 absolute">You have selected: {{countselectedPhotoIDs()}} pictures</p>
      <button class="download-btn absolute" (click)="downloadselectedPhotoIDs()">Download</button>
    </div>
  </section>
</ng-template>