import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PhotoListComponent } from '../home/photo-list/photo-list.component';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
@Component({
  selector: 'app-guest',
  standalone: true,
  imports: [PhotoListComponent],
  templateUrl: './guest.component.html',
  styleUrl: './guest.component.css'
})
export class GuestComponent implements OnInit{
  constructor(private http: HttpClient,private route:ActivatedRoute) { }
  uid = '';
  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.uid = params.get('uid') || '';
    });
    console.log(this.uid);
  }
}
