import { Component } from '@angular/core';
import { ShareList } from '../../interfaces/photo-pile'
import { CommonModule } from '@angular/common';
import { PhotoPile } from '../../interfaces/photo-pile';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { PhotoShareResponse } from '../../interfaces/response';
import { environment } from '../../environment/local_environment';
@Component({
  selector: 'app-photo-share-section',
  standalone: true,
  imports: [CommonModule,FormsModule,MatSnackBarModule],
  templateUrl: './photo-share-section.component.html',
  styleUrl: './photo-share-section.component.css'
})

export class PhotoShareSectionComponent{
  constructor(private http: HttpClient,private snackBar: MatSnackBar) { }
  effectivePeriodValue = 30;
  isPopupOpen = false;
  shareLink = '';
  shareList : ShareList = {
    Photopiles: [],
    TimeLimit: 0,
  }
  onDragStart(event: DragEvent, photopile: PhotoPile) {
    event.dataTransfer?.setData('application/json', JSON.stringify(photopile));
    const draggingElement = event.target as HTMLElement;
    draggingElement.classList.add('dragging');
    console.log(photopile);
  }
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragEnter(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const draggedPhotopile: PhotoPile = JSON.parse(event.dataTransfer?.getData('application/json') ?? '');
    const draggingElement = document.querySelector('.dragging')
    if (draggingElement) {
      draggingElement.classList.remove('dragging');
    }
    if (draggedPhotopile && !this.shareList.Photopiles.some((p) => p.GroupID === draggedPhotopile.GroupID)) {
      this.shareList.Photopiles.push(draggedPhotopile);
      // print out shareList
    }
  }

  removePhotopileFromSharedList(photopile: PhotoPile) {
    this.shareList.Photopiles = this.shareList.Photopiles.filter((p) => p.GroupID !== photopile.GroupID);
  }
  openPopup(shareLink: string) {
    this.isPopupOpen = true;
    this.shareLink = shareLink;
  }
  closePopup() {
    this.isPopupOpen = false;
    this.shareList =  {
      Photopiles: [],
      TimeLimit: 0,
    }
  }
  copyLink(inputElement: HTMLInputElement): void {
    inputElement.select();
    document.execCommand('copy');
    alert('Link copied to clipboard!');
  }
  generateShareLink() {
    // generate random and not duplicated id
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.shareList.TimeLimit = this.effectivePeriodValue;
    this.http.post<PhotoShareResponse>(environment.backendUrl+'/storeUID2DB', JSON.stringify(this.shareList),{headers}).subscribe({
      next: (response) => {
        console.log(response.IsSuccessful);
        if (response.IsSuccessful) {
          this.openPopup(response.ShareLink);
        }
        else {
          this.snackBar.open('Failed to generate share link', 'Close', {duration: 2000});
        }
      }
    })
  }
}
