import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import { DeviceListComponent } from './device-list/device-list.component';
import { PhotoListComponent } from './photo-list/photo-list.component';
import { PhotoSearchbarComponent } from './photo-searchbar/photo-searchbar.component';
import { UploadSectionComponent } from './upload-section/upload-section.component';
import { PhotoShareSectionComponent } from './photo-share-section/photo-share-section.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      { path: 'device-list', component: DeviceListComponent },
      { path: 'photo-list', component: PhotoListComponent },
      { path: 'photo-searchbar', component: PhotoSearchbarComponent },
      { path: 'upload-section', component: UploadSectionComponent },
      { path: 'photo-share-section', component: PhotoShareSectionComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }