import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UploadSuccessService {
  private uploadSuccess$ = new Subject<void>();

  get uploadSuccess() {
    return this.uploadSuccess$.asObservable();
  }

  notifyUploadSuccess() {
    this.uploadSuccess$.next();
  }
}
