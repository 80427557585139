export interface Devices {
    id: number;
    name: string;
    type: string;
    capacity: number;
    usage: number;
  }
  
  export const devices = [
    {
        id: 1,
        name: 'device1',
        type:'phone',
        capacity: 1000,
        usage: 200
    },

    {
        id: 2,
        name: 'device2',
        type:'ipad',
        capacity: 2000,
        usage: 300
    },

    {
        id: 3,
        name: 'device3',
        type:'sdcard',
        capacity: 1500,
        usage: 600
    },
    {
        id: 4,
        name: 'device4',
        type:'sdcard',
        capacity: 1000,
        usage: 200
    }
  ];