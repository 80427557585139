import { Component,ElementRef, AfterViewInit, ViewChild, Renderer2,Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home.routes';
import { Store,StoreModule } from '@ngrx/store';
import { DeviceListComponent } from './device-list/device-list.component';
import { PhotoListComponent } from './photo-list/photo-list.component';
import { PhotoSearchbarComponent } from './photo-searchbar/photo-searchbar.component';
import { UploadSectionComponent } from './upload-section/upload-section.component';
import { PhotoShareSectionComponent } from './photo-share-section/photo-share-section.component';
import { changeDeviceListState } from './home.action';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule, HomeRoutingModule, DeviceListComponent, PhotoListComponent, PhotoSearchbarComponent,  UploadSectionComponent, PhotoShareSectionComponent, StoreModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent {
  @ViewChild('parentElement') parentElement!: ElementRef;
  @ViewChild('devicePanel') devicePanel!: DeviceListComponent;
  @ViewChild('transparentElement') transparentElement!: ElementRef;
  @Output() devicePanelWidth = new EventEmitter<number>();
  parentIsVisiable: boolean = true;
  receivedWidth: number | null = null;
  ngOnInit () {
    this.devicePanelWidth.subscribe((value) => {
      this.receivedWidth = value;
      this.updateTransparentElementWidth();
    });
  }
  emitNewWidth() {
    var devicePanelWidth:number = this.devicePanel.getElementWidth();
    this.devicePanelWidth.emit(devicePanelWidth);
    console.log(devicePanelWidth);
  }
  toggleDeviceListVisibility() {
    this.parentIsVisiable = !this.parentIsVisiable;
    setTimeout(() => {
      this.emitNewWidth();
    }, 3);
  }
  constructor(private store: Store<{ deviceListVisibility: boolean; }>,private renderer: Renderer2) { }

  updateTransparentElementWidth() {
    const absoluteElementWidth = this.receivedWidth;
    this.renderer.setStyle(this.transparentElement.nativeElement, 'width', `${absoluteElementWidth}px`);
  }
  deviceListVisibility: Observable<boolean> = this.store.select('deviceListVisibility');
  changeDeviceListState() {
    this.store.dispatch(changeDeviceListState());
  }
  onWidthChange(value: number) {
    this.receivedWidth = value;
    console.log('revei');
    this.updateTransparentElementWidth();
  }
}
