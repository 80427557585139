<!-- <div class="container" class = "flex flex-row flex-1 h-max">
  <router-outlet></router-outlet>

  <div name = "left-row" class="flex basis-1/4 flex-col p-4 space-y-4">
    <app-user-panel></app-user-panel>
    <app-device-list></app-device-list>
  </div>

  <div name = "middle-row" class="flex basis-1/2 flex-col p-4 space-y-5 h-max">
    <p class = "font-sans text-3xl font-medium"> Recent Stash-es</p>
    <app-upload-section></app-upload-section>
    <app-photo-searchbar></app-photo-searchbar>
    <app-photo-list class="h-max"></app-photo-list>
  </div>

  <div name = "right-row" class="basis-1/4 flex-col">
    <app-photo-share-section></app-photo-share-section>
  </div>
</div> -->

<nav>
</nav>
<router-outlet></router-outlet>