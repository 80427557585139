<div #devicePanel name = 'device-panel' class = "absolute p-1 justify-items-center bg-gray-100 rounded-md shadow-md w-52 h-12" *ngIf="isVisiable" (mouseleave)="moveoutToggleDeviceListVisibility()">
  <div *ngFor="let device of devices" class = "absolute content-center" style = "left: {{(device.id-1)*50}}px">
    <div class = "device-subpanel" [ngSwitch]="device.type" *ngIf = "device != currentDevice">
      <svg class = "progress-circle device-icon-svg" style = "--percent: {{(device.usage/device.capacity)*100}}">
        <circle stroke = "var(--inactive-color)"  />
        <circle stroke = "var(--color)" style = "stroke-dasharray: calc(2*3.1415*(var(--size) - var(--border-width)) / 2 * (var(--percent) / 100)), 1000"/>
      </svg>
      <i class="fas fa-hdd device-icon unselected-icon" *ngSwitchCase = "'sdcard'"></i>
      <i class="fa-solid fa-mobile-button unselected-icon" *ngSwitchCase = "'phone'"></i>
      <i class="fa-solid fa-tablet-screen-button unselected-icon" *ngSwitchCase = "'ipad'"></i>
    </div>
    <div class = "device-subpanel rounded-md" [ngSwitch]="device.type" style = "background-color: lightgray" *ngIf = "device == currentDevice">
      <svg class = "progress-circle device-icon-svg" style = "--percent: {{(device.usage/device.capacity)*100}} ;--inactive-color: white">
        <circle stroke = "var(--inactive-color)"  />
        <circle stroke = "var(--color)" style = "stroke-dasharray: calc(2*3.1415*(var(--size) - var(--border-width)) / 2 * (var(--percent) / 100)), 1000"/>
      </svg>
      <i class="fas fa-hdd device-icon selected-icon" *ngSwitchCase = "'sdcard'"></i>
      <i class="fa-solid fa-mobile-button selected-icon" *ngSwitchCase = "'phone'"></i>
      <i class="fa-solid fa-tablet-screen-button selected-icon" *ngSwitchCase = "'ipad'"></i>
    </div>
  </div>
</div>

<div #devicePanel name = 'device-panel' class = "absolute p-1 justify-items-center bg-gray-100 rounded-md shadow-md w-min" *ngIf="!isVisiable">
    <div class = "device-subpanel rounded-md" [ngSwitch]="currentDevice.type" style = "background-color: lightgray" (mouseenter)="toggleDeviceListVisibility()">
      <svg class = "progress-circle device-icon-svg" style = "--percent: {{(currentDevice.usage/currentDevice.capacity)*100}} ;--inactive-color: white">
        <circle stroke = "var(--inactive-color)"  />
        <circle stroke = "var(--color)" style = "stroke-dasharray: calc(2*3.1415*(var(--size) - var(--border-width)) / 2 * (var(--percent) / 100)), 1000"/>
      </svg>
      <i class="fas fa-hdd device-icon selected-icon" *ngSwitchCase = "'sdcard'"></i>
      <i class="fa-solid fa-mobile-button selected-icon" *ngSwitchCase = "'phone'"></i>
      <i class="fa-solid fa-tablet-screen-button selected-icon" *ngSwitchCase = "'ipad'"></i>
    </div>
</div>

