<div name = 'photo-upload'>
    <!-- <form (ngSubmit) = "onSubmit()" enctype="multipart/form-data">
        <input type="file" (change) = "onPhotoSelection($event)"/>
        <div *ngIf="previewUrl">
            <img [src]="previewUrl" alt="Image Preview" width="300">
        </div>
        <button type="submit" value="Upload"class="border-2 border-gray-400 rounded-md w-1/4"> Upload </button>
    </form> -->
    <button name = "upload-menu-button" (click) = "toggleMenuVisibility()" style = "background-color: #66ccff;"class="pl-10 pr-10 pt-2.5 pb-2.5 rounded-lg hover:bg-slate-200">
        <i class="fa-solid fa-upload" style = "font-size: 20px;color:white"></i>
    </button>
    <div name = "upload-menu" class = "fixed bg-slate-100 border-gray-300 shadow-md p-10 rounded-lg z-50" style = "right:18%;" *ngIf = "menuVisible">
        <button name = "uploadFromLocal" (click) = "openFileSelector()" class="pl-10 pr-10 pt-5 pb-5 rounded-lg hover:bg-slate-200">
            <i class="fa-solid fa-laptop"></i> <p>Upload from Local</p>
        </button>
    </div>
    <input type="file" #fileInput style="display: none;" (change)="onPhotoSelection($event)" />
</div>